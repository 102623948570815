<template>
  <div class="about">
    <header class="header">About Page</header>

    <section class="main">
      <div class="title">
          New Changes -- version 1.0.3
      </div>

      <div class="description">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laborum beatae in dolorum nihil
        facere minima dignissimos voluptas, at exercitationem libero necessitatibus deleniti,
        aliquid, tempora consequatur eveniet tenetur recusandae autem! Quam?
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.about {
  max-width: 2500px;

  margin: 0 auto 0;
  overflow: auto;

  border: 1px solid #c4cde0;
}

.header {
  grid-area: header;
  height: 100%;

  background: #f1f1f1;

  box-sizing: border-box;

  border-left: 1px solid #c4cde0;
  border-right: 1px solid #c4cde0;
  border-top: 1px solid #c4cde0;
}
.main{
    width: 100%;
}

.title{
    border-bottom: 1px solid #d8dfea;
    background: #a3bbd5;
    text-align: left;
    padding: 0 0 0 20px;
}
.description{
    max-width: 50%;
    font-size: 13px;
    padding: 0 0 0 20px;
    text-align: left;
}
</style>
